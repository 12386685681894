import { createRouter, createWebHistory } from 'vue-router'
import Telegram from '../layouts/Telegram.vue'
import Empty from '../layouts/Empty.vue'
import { useLayoutStore } from '../stores/layout'

const routes = [
	{
		path: '/',
		meta: {
			layout: Empty
		},
		component: () => import('./Landing.vue')
	},
	{
		path: '/undress',
		meta: {
			stickyTop: true
		},
		component: () => import('./Undress.vue')
	},
	{
		path: '/ai-models',
		meta: {
			stickyTop: true
		},
		component: () => import('./Models.vue')
	},
	{
		path: '/topup',
		meta: {
			layout: Telegram
		},
		component: () => import('./tg/TopUp.vue'),
		alias: '/tg/topup'
	},
	{
		path: '/tg/filters',
		meta: {
			layout: Telegram
		},
		component: () => import('./tg/Filters.vue')
	},
	{
		path: '/result/:uuid',
		component: () => import('./Result.vue')
	},
	{
		path: '/my-gallery',
		component: () => import('./Gallery.vue')
	},
	{
		path: '/api',
		component: () => import('./API.vue')
	},
	{
		path: '/referrals',
		meta: {
			stickyTop: true
		},
		component: () => import('./Referrals.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import('./404.vue')
	}
]

export const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return {
			el: '#view',
			top: 0
		}
	}
})

router.beforeEach((to) => {
	if (to.meta && to.meta.layout) {
		const layoutStore = useLayoutStore()

		layoutStore.setForceLayout(to.meta.layout)
	} else {
		const layoutStore = useLayoutStore()

		if (layoutStore.forceLayout) {
			layoutStore.setForceLayout()
		}
	}
})
