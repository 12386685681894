/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n\t\tmutation getInvoiceToken($initData: String) {\n\t\t\tgetInvoiceToken(initData: $initData)\n\t\t}\n\t": types.GetInvoiceTokenDocument,
    "\n\t\tmutation sendPartnerRequest(\n\t\t\t$name: String!\n\t\t\t$link: String!\n\t\t\t$amount: String!\n\t\t\t$email: String\n\t\t\t$telegram: String\n\t\t) {\n\t\t\tsendPartnerRequest(\n\t\t\t\tname: $name\n\t\t\t\tlink: $link\n\t\t\t\tamount: $amount\n\t\t\t\temail: $email\n\t\t\t\ttelegram: $telegram\n\t\t\t)\n\t\t}\n\t": types.SendPartnerRequestDocument,
    "\n\t\tmutation ChangePassword($password: Password!, $newPassword: Password!) {\n\t\t\tchangePassword(password: $password, newPassword: $newPassword) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.ChangePasswordDocument,
    "\n\t\tquery getActiveSessions {\n\t\t\tgetActiveSessions {\n\t\t\t\tbrowser\n\t\t\t\tcurrent\n\t\t\t\tdevice\n\t\t\t\tlastUsedAt\n\t\t\t}\n\t\t}\n\t": types.GetActiveSessionsDocument,
    "\n\t\t\tmutation closeAllSessions {\n\t\t\t\tcloseAllSessions\n\t\t\t}\n\t\t": types.CloseAllSessionsDocument,
    "\n\t\t\tmutation createApiKey {\n\t\t\t\tcreateApiKey\n\t\t\t}\n\t\t": types.CreateApiKeyDocument,
    "\n\t\t\tmutation deleteAccount {\n\t\t\t\tdeleteAccount\n\t\t\t}\n\t\t": types.DeleteAccountDocument,
    "\n\t\tmutation deleteGenerations($uuids: [UUID!]) {\n\t\t\tdeleteGenerations(uuids: $uuids)\n\t\t}\n\t": types.DeleteGenerationsDocument,
    "\n\t\tmutation deleteResult($uuid: UUID!) {\n\t\t\tdeleteResult(uuid: $uuid)\n\t\t}\n\t": types.DeleteResultDocument,
    "\n\t\tmutation resetPassword($email: EmailAddress!) {\n\t\t\tresetPassword(email: $email)\n\t\t}\n\t": types.ResetPasswordDocument,
    "\n\t\tmutation checkPasswordResetCode($email: EmailAddress!, $code: String!) {\n\t\t\tcheckPasswordResetCode(email: $email, code: $code)\n\t\t}\n\t": types.CheckPasswordResetCodeDocument,
    "\n\t\tmutation ChangePasswordWithToken(\n\t\t\t$email: EmailAddress!\n\t\t\t$newPassword: Password!\n\t\t\t$token: String!\n\t\t) {\n\t\t\tchangePasswordWithToken(\n\t\t\t\temail: $email\n\t\t\t\tnewPassword: $newPassword\n\t\t\t\ttoken: $token\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.ChangePasswordWithTokenDocument,
    "\n\t\tquery withdrawReferrerHistory {\n\t\t\twithdrawReferrerHistory {\n\t\t\t\taddress\n\t\t\t\tamount\n\t\t\t\tcreatedAt\n\t\t\t\tnetwork\n\t\t\t\tstatus\n\t\t\t}\n\t\t}\n\t": types.WithdrawReferrerHistoryDocument,
    "\n\t\tmutation withdrawReferrerBalance(\n\t\t\t$amount: Int!\n\t\t\t$input: WithdrawReferrerBalanceInput!\n\t\t) {\n\t\t\twithdrawReferrerBalance(amount: $amount, input: $input)\n\t\t}\n\t": types.WithdrawReferrerBalanceDocument,
    "\n\t\tmutation signIn($email: EmailAddress!, $password: Password!) {\n\t\t\tsignIn(email: $email, password: $password) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.SignInDocument,
    "\n\t\tmutation oAuthSignIn($provider: AuthProvider!) {\n\t\t\toAuthSignIn(provider: $provider)\n\t\t}\n\t": types.OAuthSignInDocument,
    "\n\t\tmutation signUp(\n\t\t\t$email: EmailAddress!\n\t\t\t$password: Password!\n\t\t\t$code: String\n\t\t) {\n\t\t\tsignUp(email: $email, password: $password, referralCode: $code) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.SignUpDocument,
    "\n\t\tmutation acceptTerms {\n\t\t\tacceptTerms {\n\t\t\t\tacceptedTerms\n\t\t\t\tavatar\n\t\t\t\temailVerified\n\t\t\t\tname\n\t\t\t\tprovider\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.AcceptTermsDocument,
    "\n\t\tquery creditsPrices {\n\t\t\tcreditsPrices {\n\t\t\t\tcredits\n\t\t\t\tusdPrice\n\t\t\t}\n\t\t}\n\t": types.CreditsPricesDocument,
    "\n\t\tquery getPaymentProviders($initData: String, $botUsername: String) {\n\t\t\tgetPaymentProviders(initData: $initData, botUsername: $botUsername) {\n\t\t\t\tcurrency\n\t\t\t\tproviders {\n\t\t\t\t\trequired\n\t\t\t\t\tlogo\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t\tvisible\n\t\t\t\t\tminPayment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t": types.GetPaymentProvidersDocument,
    "\n\t\tmutation createInvoice(\n\t\t\t$providerId: Int!\n\t\t\t$credits: Int!\n\t\t\t$data: InvoiceData\n\t\t\t$initData: String\n\t\t\t$botUsername: String\n\t\t) {\n\t\t\tcreateInvoice(\n\t\t\t\tproviderId: $providerId\n\t\t\t\tcredits: $credits\n\t\t\t\tdata: $data\n\t\t\t\tinitData: $initData\n\t\t\t\tbotUsername: $botUsername\n\t\t\t)\n\t\t}\n\t": types.CreateInvoiceDocument,
    "\n\t\tmutation sendVerifyEmail {\n\t\t\tsendVerifyEmail\n\t\t}\n\t": types.SendVerifyEmailDocument,
    "\n\t\tmutation verifyEmail($code: String!) {\n\t\t\tverifyEmail(code: $code) {\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tprovider\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.VerifyEmailDocument,
    "\n\t\t\t\tquery args {\n\t\t\t\t\tgetAvatarGenerationTemplates {\n\t\t\t\t\t\texamples\n\t\t\t\t\t\tdescription\n\t\t\t\t\t\tlogo\n\t\t\t\t\t\tname\n\t\t\t\t\t\tchoice\n\t\t\t\t\t\tkwargs\n\t\t\t\t\t\tid\n\t\t\t\t\t\ttags\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t": types.ArgsDocument,
    "\n\t\t\t\tquery getUser {\n\t\t\t\t\tcurrent {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t\tname\n\t\t\t\t\t\temailVerified\n\t\t\t\t\t\tavatar\n\t\t\t\t\t\tprovider\n\t\t\t\t\t\tacceptedTerms\n\t\t\t\t\t\tintercomHash\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t": types.GetUserDocument,
    "\n\t\t\t\tsubscription userUpdate {\n\t\t\t\t\tuserUpdate {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t\tname\n\t\t\t\t\t\temailVerified\n\t\t\t\t\t\tavatar\n\t\t\t\t\t\tprovider\n\t\t\t\t\t\tacceptedTerms\n\t\t\t\t\t\tintercomHash\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t": types.UserUpdateDocument,
    "\n\t\t\t\tsubscription balanceUpdate {\n\t\t\t\t\tbalanceUpdate {\n\t\t\t\t\t\tmessage\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t": types.BalanceUpdateDocument,
    "\n\t\t\t\tmutation logout {\n\t\t\t\t\tlogout\n\t\t\t\t}\n\t\t\t": types.LogoutDocument,
    "\n\t\tquery getGallery($perPage: Int!, $page: Int!, $types: [GenerationTypes!]) {\n\t\t\tgetGallery(perPage: $perPage, page: $page, types: $types) {\n\t\t\t\tgenerations {\n\t\t\t\t\tcategory\n\t\t\t\t\tcreated\n\t\t\t\t\tresults {\n\t\t\t\t\t\terror\n\t\t\t\t\t\textension\n\t\t\t\t\t\tuploaded\n\t\t\t\t\t\tuuid\n\t\t\t\t\t}\n\t\t\t\t\tstatus\n\t\t\t\t\tuuid\n\t\t\t\t\tboobsSize\n\t\t\t\t\tregion\n\t\t\t\t\tpussyType\n\t\t\t\t}\n\t\t\t\tpages\n\t\t\t}\n\t\t}\n\t": types.GetGalleryDocument,
    "\n\t\tsubscription generationTaskResult {\n\t\t\tgenerationTaskResult {\n\t\t\t\terror\n\t\t\t\tsuccess\n\t\t\t\tresultUuids\n\t\t\t\tgenerationUuid\n\t\t\t\tregion\n\t\t\t}\n\t\t}\n\t": types.GenerationTaskResultDocument,
    "\n\t\tmutation createGenerationAvatar(\n\t\t\t$arguments: JSON!\n\t\t\t$photos: [PhotoInput!]\n\t\t\t$uuid: UUID\n\t\t\t$id: Int!\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tavatar: {\n\t\t\t\t\t\targuments: $arguments\n\t\t\t\t\t\tphotos: $photos\n\t\t\t\t\t\tgenerationUuid: $uuid\n\t\t\t\t\t\tid: $id\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t": types.CreateGenerationAvatarDocument,
    "\n\t\tmutation photoUploadSuccess($uuid: UUID!) {\n\t\t\tphotoUploadSuccess(generationUuid: $uuid)\n\t\t}\n\t": types.PhotoUploadSuccessDocument,
    "\n\t\t\tquery getAvatarRegenerationData($uuid: UUID!) {\n\t\t\t\tgetAvatarRegenerationData(uuid: $uuid) {\n\t\t\t\t\toriginals {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\textension\n\t\t\t\t\t}\n\t\t\t\t\tpromptArgs\n\t\t\t\t\ttype\n\t\t\t\t\ttemplateId\n\t\t\t\t}\n\t\t\t}\n\t\t": types.GetAvatarRegenerationDataDocument,
    "\n\t\tquery referralInfo {\n\t\t\treferralInfo {\n\t\t\t\tlevel\n\t\t\t\tfirstDepositBonus\n\t\t\t\tfutureDepositBonus\n\t\t\t\tcurrentLevelReferrals\n\t\t\t\tnextLevelReferrals\n\t\t\t\tcurrentLevelDeposits\n\t\t\t\tnextLevelDeposits\n\t\t\t\tbalance\n\t\t\t\tcodes {\n\t\t\t\t\tcode\n\t\t\t\t\tprofit\n\t\t\t\t\treferrals\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t": types.ReferralInfoDocument,
    "\n\t\tquery charts($codes: [String!]) {\n\t\t\treferralCodesCharts(codes: $codes) {\n\t\t\t\tcharts {\n\t\t\t\t\tname\n\t\t\t\t\tdeposits {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\tprofit {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t\ttotal\n\t\t\t\t\t}\n\t\t\t\t\treferrals {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t": types.ChartsDocument,
    "\n\t\tmutation createReferralCode {\n\t\t\tcreateReferralCode\n\t\t}\n\t": types.CreateReferralCodeDocument,
    "\n\t\tmutation createGenerationByUUID(\n\t\t\t$category: GenerationCategories!\n\t\t\t$uuid: UUID\n\t\t\t$size: BoobsSizes!\n\t\t\t$pussy: PussyTypes!\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tclothoff: {\n\t\t\t\t\t\tcategory: $category\n\t\t\t\t\t\tgenerationUuid: $uuid\n\t\t\t\t\t\tboobsSize: $size\n\t\t\t\t\t\tpussyType: $pussy\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t": types.CreateGenerationByUuidDocument,
    "\n\tquery getGeneration($uuid: UUID!) {\n\t\tgeneration: getGeneration(uuid: $uuid) {\n\t\t\ttype\n\t\t\tstatus\n\t\t\tresults {\n\t\t\t\terror\n\t\t\t\textension\n\t\t\t\tuploaded\n\t\t\t\tuuid\n\t\t\t\tliked\n\t\t\t}\n\t\t\toriginals {\n\t\t\t\tuuid\n\t\t\t\textension\n\t\t\t\tuploaded\n\t\t\t}\n\t\t\tcreated\n\t\t\tcategory\n\t\t\tboobsSize\n\t\t\tregion\n\t\t\tpussyType\n\t\t}\n\t}\n": types.GetGenerationDocument,
    "\n\t\tmutation likeGenerationTask($uuid: UUID!, $like: Boolean!) {\n\t\t\tlikeGenerationTask(uuid: $uuid, like: $like)\n\t\t}\n\t": types.LikeGenerationTaskDocument,
    "\n\t\tmutation createGenerationClothoff(\n\t\t\t$category: GenerationCategories!\n\t\t\t$size: BoobsSizes!\n\t\t\t$pussy: PussyTypes!\n\t\t\t$photos: [PhotoInput!]\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tclothoff: {\n\t\t\t\t\t\tcategory: $category\n\t\t\t\t\t\tphotos: $photos\n\t\t\t\t\t\tboobsSize: $size\n\t\t\t\t\t\tpussyType: $pussy\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t": types.CreateGenerationClothoffDocument,
    "\n\t\tmutation createTelegramAvatarGeneration(\n\t\t\t$id: Int!\n\t\t\t$initData: String!\n\t\t\t$botUsername: String!\n\t\t\t$arguments: JSON!\n\t\t\t$groupId: String!\n\t\t) {\n\t\t\tcreateTelegramAvatarGeneration(\n\t\t\t\tid: $id\n\t\t\t\tinitData: $initData\n\t\t\t\tbotUsername: $botUsername\n\t\t\t\targuments: $arguments\n\t\t\t\tgroupId: $groupId\n\t\t\t)\n\t\t}\n\t": types.CreateTelegramAvatarGenerationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation getInvoiceToken($initData: String) {\n\t\t\tgetInvoiceToken(initData: $initData)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation getInvoiceToken($initData: String) {\n\t\t\tgetInvoiceToken(initData: $initData)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation sendPartnerRequest(\n\t\t\t$name: String!\n\t\t\t$link: String!\n\t\t\t$amount: String!\n\t\t\t$email: String\n\t\t\t$telegram: String\n\t\t) {\n\t\t\tsendPartnerRequest(\n\t\t\t\tname: $name\n\t\t\t\tlink: $link\n\t\t\t\tamount: $amount\n\t\t\t\temail: $email\n\t\t\t\ttelegram: $telegram\n\t\t\t)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation sendPartnerRequest(\n\t\t\t$name: String!\n\t\t\t$link: String!\n\t\t\t$amount: String!\n\t\t\t$email: String\n\t\t\t$telegram: String\n\t\t) {\n\t\t\tsendPartnerRequest(\n\t\t\t\tname: $name\n\t\t\t\tlink: $link\n\t\t\t\tamount: $amount\n\t\t\t\temail: $email\n\t\t\t\ttelegram: $telegram\n\t\t\t)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation ChangePassword($password: Password!, $newPassword: Password!) {\n\t\t\tchangePassword(password: $password, newPassword: $newPassword) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation ChangePassword($password: Password!, $newPassword: Password!) {\n\t\t\tchangePassword(password: $password, newPassword: $newPassword) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery getActiveSessions {\n\t\t\tgetActiveSessions {\n\t\t\t\tbrowser\n\t\t\t\tcurrent\n\t\t\t\tdevice\n\t\t\t\tlastUsedAt\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery getActiveSessions {\n\t\t\tgetActiveSessions {\n\t\t\t\tbrowser\n\t\t\t\tcurrent\n\t\t\t\tdevice\n\t\t\t\tlastUsedAt\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tmutation closeAllSessions {\n\t\t\t\tcloseAllSessions\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tmutation closeAllSessions {\n\t\t\t\tcloseAllSessions\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tmutation createApiKey {\n\t\t\t\tcreateApiKey\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tmutation createApiKey {\n\t\t\t\tcreateApiKey\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tmutation deleteAccount {\n\t\t\t\tdeleteAccount\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tmutation deleteAccount {\n\t\t\t\tdeleteAccount\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation deleteGenerations($uuids: [UUID!]) {\n\t\t\tdeleteGenerations(uuids: $uuids)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation deleteGenerations($uuids: [UUID!]) {\n\t\t\tdeleteGenerations(uuids: $uuids)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation deleteResult($uuid: UUID!) {\n\t\t\tdeleteResult(uuid: $uuid)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation deleteResult($uuid: UUID!) {\n\t\t\tdeleteResult(uuid: $uuid)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation resetPassword($email: EmailAddress!) {\n\t\t\tresetPassword(email: $email)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation resetPassword($email: EmailAddress!) {\n\t\t\tresetPassword(email: $email)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation checkPasswordResetCode($email: EmailAddress!, $code: String!) {\n\t\t\tcheckPasswordResetCode(email: $email, code: $code)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation checkPasswordResetCode($email: EmailAddress!, $code: String!) {\n\t\t\tcheckPasswordResetCode(email: $email, code: $code)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation ChangePasswordWithToken(\n\t\t\t$email: EmailAddress!\n\t\t\t$newPassword: Password!\n\t\t\t$token: String!\n\t\t) {\n\t\t\tchangePasswordWithToken(\n\t\t\t\temail: $email\n\t\t\t\tnewPassword: $newPassword\n\t\t\t\ttoken: $token\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation ChangePasswordWithToken(\n\t\t\t$email: EmailAddress!\n\t\t\t$newPassword: Password!\n\t\t\t$token: String!\n\t\t) {\n\t\t\tchangePasswordWithToken(\n\t\t\t\temail: $email\n\t\t\t\tnewPassword: $newPassword\n\t\t\t\ttoken: $token\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery withdrawReferrerHistory {\n\t\t\twithdrawReferrerHistory {\n\t\t\t\taddress\n\t\t\t\tamount\n\t\t\t\tcreatedAt\n\t\t\t\tnetwork\n\t\t\t\tstatus\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery withdrawReferrerHistory {\n\t\t\twithdrawReferrerHistory {\n\t\t\t\taddress\n\t\t\t\tamount\n\t\t\t\tcreatedAt\n\t\t\t\tnetwork\n\t\t\t\tstatus\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation withdrawReferrerBalance(\n\t\t\t$amount: Int!\n\t\t\t$input: WithdrawReferrerBalanceInput!\n\t\t) {\n\t\t\twithdrawReferrerBalance(amount: $amount, input: $input)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation withdrawReferrerBalance(\n\t\t\t$amount: Int!\n\t\t\t$input: WithdrawReferrerBalanceInput!\n\t\t) {\n\t\t\twithdrawReferrerBalance(amount: $amount, input: $input)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation signIn($email: EmailAddress!, $password: Password!) {\n\t\t\tsignIn(email: $email, password: $password) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation signIn($email: EmailAddress!, $password: Password!) {\n\t\t\tsignIn(email: $email, password: $password) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation oAuthSignIn($provider: AuthProvider!) {\n\t\t\toAuthSignIn(provider: $provider)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation oAuthSignIn($provider: AuthProvider!) {\n\t\t\toAuthSignIn(provider: $provider)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation signUp(\n\t\t\t$email: EmailAddress!\n\t\t\t$password: Password!\n\t\t\t$code: String\n\t\t) {\n\t\t\tsignUp(email: $email, password: $password, referralCode: $code) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation signUp(\n\t\t\t$email: EmailAddress!\n\t\t\t$password: Password!\n\t\t\t$code: String\n\t\t) {\n\t\t\tsignUp(email: $email, password: $password, referralCode: $code) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation acceptTerms {\n\t\t\tacceptTerms {\n\t\t\t\tacceptedTerms\n\t\t\t\tavatar\n\t\t\t\temailVerified\n\t\t\t\tname\n\t\t\t\tprovider\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation acceptTerms {\n\t\t\tacceptTerms {\n\t\t\t\tacceptedTerms\n\t\t\t\tavatar\n\t\t\t\temailVerified\n\t\t\t\tname\n\t\t\t\tprovider\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery creditsPrices {\n\t\t\tcreditsPrices {\n\t\t\t\tcredits\n\t\t\t\tusdPrice\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery creditsPrices {\n\t\t\tcreditsPrices {\n\t\t\t\tcredits\n\t\t\t\tusdPrice\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery getPaymentProviders($initData: String, $botUsername: String) {\n\t\t\tgetPaymentProviders(initData: $initData, botUsername: $botUsername) {\n\t\t\t\tcurrency\n\t\t\t\tproviders {\n\t\t\t\t\trequired\n\t\t\t\t\tlogo\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t\tvisible\n\t\t\t\t\tminPayment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery getPaymentProviders($initData: String, $botUsername: String) {\n\t\t\tgetPaymentProviders(initData: $initData, botUsername: $botUsername) {\n\t\t\t\tcurrency\n\t\t\t\tproviders {\n\t\t\t\t\trequired\n\t\t\t\t\tlogo\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t\tvisible\n\t\t\t\t\tminPayment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createInvoice(\n\t\t\t$providerId: Int!\n\t\t\t$credits: Int!\n\t\t\t$data: InvoiceData\n\t\t\t$initData: String\n\t\t\t$botUsername: String\n\t\t) {\n\t\t\tcreateInvoice(\n\t\t\t\tproviderId: $providerId\n\t\t\t\tcredits: $credits\n\t\t\t\tdata: $data\n\t\t\t\tinitData: $initData\n\t\t\t\tbotUsername: $botUsername\n\t\t\t)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createInvoice(\n\t\t\t$providerId: Int!\n\t\t\t$credits: Int!\n\t\t\t$data: InvoiceData\n\t\t\t$initData: String\n\t\t\t$botUsername: String\n\t\t) {\n\t\t\tcreateInvoice(\n\t\t\t\tproviderId: $providerId\n\t\t\t\tcredits: $credits\n\t\t\t\tdata: $data\n\t\t\t\tinitData: $initData\n\t\t\t\tbotUsername: $botUsername\n\t\t\t)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation sendVerifyEmail {\n\t\t\tsendVerifyEmail\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation sendVerifyEmail {\n\t\t\tsendVerifyEmail\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation verifyEmail($code: String!) {\n\t\t\tverifyEmail(code: $code) {\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tprovider\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation verifyEmail($code: String!) {\n\t\t\tverifyEmail(code: $code) {\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tprovider\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\t\tquery args {\n\t\t\t\t\tgetAvatarGenerationTemplates {\n\t\t\t\t\t\texamples\n\t\t\t\t\t\tdescription\n\t\t\t\t\t\tlogo\n\t\t\t\t\t\tname\n\t\t\t\t\t\tchoice\n\t\t\t\t\t\tkwargs\n\t\t\t\t\t\tid\n\t\t\t\t\t\ttags\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"): (typeof documents)["\n\t\t\t\tquery args {\n\t\t\t\t\tgetAvatarGenerationTemplates {\n\t\t\t\t\t\texamples\n\t\t\t\t\t\tdescription\n\t\t\t\t\t\tlogo\n\t\t\t\t\t\tname\n\t\t\t\t\t\tchoice\n\t\t\t\t\t\tkwargs\n\t\t\t\t\t\tid\n\t\t\t\t\t\ttags\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\t\tquery getUser {\n\t\t\t\t\tcurrent {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t\tname\n\t\t\t\t\t\temailVerified\n\t\t\t\t\t\tavatar\n\t\t\t\t\t\tprovider\n\t\t\t\t\t\tacceptedTerms\n\t\t\t\t\t\tintercomHash\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"): (typeof documents)["\n\t\t\t\tquery getUser {\n\t\t\t\t\tcurrent {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t\tname\n\t\t\t\t\t\temailVerified\n\t\t\t\t\t\tavatar\n\t\t\t\t\t\tprovider\n\t\t\t\t\t\tacceptedTerms\n\t\t\t\t\t\tintercomHash\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\t\tsubscription userUpdate {\n\t\t\t\t\tuserUpdate {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t\tname\n\t\t\t\t\t\temailVerified\n\t\t\t\t\t\tavatar\n\t\t\t\t\t\tprovider\n\t\t\t\t\t\tacceptedTerms\n\t\t\t\t\t\tintercomHash\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"): (typeof documents)["\n\t\t\t\tsubscription userUpdate {\n\t\t\t\t\tuserUpdate {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t\tname\n\t\t\t\t\t\temailVerified\n\t\t\t\t\t\tavatar\n\t\t\t\t\t\tprovider\n\t\t\t\t\t\tacceptedTerms\n\t\t\t\t\t\tintercomHash\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\t\tsubscription balanceUpdate {\n\t\t\t\t\tbalanceUpdate {\n\t\t\t\t\t\tmessage\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"): (typeof documents)["\n\t\t\t\tsubscription balanceUpdate {\n\t\t\t\t\tbalanceUpdate {\n\t\t\t\t\t\tmessage\n\t\t\t\t\t\ttotalCredits\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\t\tmutation logout {\n\t\t\t\t\tlogout\n\t\t\t\t}\n\t\t\t"): (typeof documents)["\n\t\t\t\tmutation logout {\n\t\t\t\t\tlogout\n\t\t\t\t}\n\t\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery getGallery($perPage: Int!, $page: Int!, $types: [GenerationTypes!]) {\n\t\t\tgetGallery(perPage: $perPage, page: $page, types: $types) {\n\t\t\t\tgenerations {\n\t\t\t\t\tcategory\n\t\t\t\t\tcreated\n\t\t\t\t\tresults {\n\t\t\t\t\t\terror\n\t\t\t\t\t\textension\n\t\t\t\t\t\tuploaded\n\t\t\t\t\t\tuuid\n\t\t\t\t\t}\n\t\t\t\t\tstatus\n\t\t\t\t\tuuid\n\t\t\t\t\tboobsSize\n\t\t\t\t\tregion\n\t\t\t\t\tpussyType\n\t\t\t\t}\n\t\t\t\tpages\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery getGallery($perPage: Int!, $page: Int!, $types: [GenerationTypes!]) {\n\t\t\tgetGallery(perPage: $perPage, page: $page, types: $types) {\n\t\t\t\tgenerations {\n\t\t\t\t\tcategory\n\t\t\t\t\tcreated\n\t\t\t\t\tresults {\n\t\t\t\t\t\terror\n\t\t\t\t\t\textension\n\t\t\t\t\t\tuploaded\n\t\t\t\t\t\tuuid\n\t\t\t\t\t}\n\t\t\t\t\tstatus\n\t\t\t\t\tuuid\n\t\t\t\t\tboobsSize\n\t\t\t\t\tregion\n\t\t\t\t\tpussyType\n\t\t\t\t}\n\t\t\t\tpages\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tsubscription generationTaskResult {\n\t\t\tgenerationTaskResult {\n\t\t\t\terror\n\t\t\t\tsuccess\n\t\t\t\tresultUuids\n\t\t\t\tgenerationUuid\n\t\t\t\tregion\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tsubscription generationTaskResult {\n\t\t\tgenerationTaskResult {\n\t\t\t\terror\n\t\t\t\tsuccess\n\t\t\t\tresultUuids\n\t\t\t\tgenerationUuid\n\t\t\t\tregion\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createGenerationAvatar(\n\t\t\t$arguments: JSON!\n\t\t\t$photos: [PhotoInput!]\n\t\t\t$uuid: UUID\n\t\t\t$id: Int!\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tavatar: {\n\t\t\t\t\t\targuments: $arguments\n\t\t\t\t\t\tphotos: $photos\n\t\t\t\t\t\tgenerationUuid: $uuid\n\t\t\t\t\t\tid: $id\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createGenerationAvatar(\n\t\t\t$arguments: JSON!\n\t\t\t$photos: [PhotoInput!]\n\t\t\t$uuid: UUID\n\t\t\t$id: Int!\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tavatar: {\n\t\t\t\t\t\targuments: $arguments\n\t\t\t\t\t\tphotos: $photos\n\t\t\t\t\t\tgenerationUuid: $uuid\n\t\t\t\t\t\tid: $id\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation photoUploadSuccess($uuid: UUID!) {\n\t\t\tphotoUploadSuccess(generationUuid: $uuid)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation photoUploadSuccess($uuid: UUID!) {\n\t\t\tphotoUploadSuccess(generationUuid: $uuid)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tquery getAvatarRegenerationData($uuid: UUID!) {\n\t\t\t\tgetAvatarRegenerationData(uuid: $uuid) {\n\t\t\t\t\toriginals {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\textension\n\t\t\t\t\t}\n\t\t\t\t\tpromptArgs\n\t\t\t\t\ttype\n\t\t\t\t\ttemplateId\n\t\t\t\t}\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tquery getAvatarRegenerationData($uuid: UUID!) {\n\t\t\t\tgetAvatarRegenerationData(uuid: $uuid) {\n\t\t\t\t\toriginals {\n\t\t\t\t\t\tuuid\n\t\t\t\t\t\textension\n\t\t\t\t\t}\n\t\t\t\t\tpromptArgs\n\t\t\t\t\ttype\n\t\t\t\t\ttemplateId\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery referralInfo {\n\t\t\treferralInfo {\n\t\t\t\tlevel\n\t\t\t\tfirstDepositBonus\n\t\t\t\tfutureDepositBonus\n\t\t\t\tcurrentLevelReferrals\n\t\t\t\tnextLevelReferrals\n\t\t\t\tcurrentLevelDeposits\n\t\t\t\tnextLevelDeposits\n\t\t\t\tbalance\n\t\t\t\tcodes {\n\t\t\t\t\tcode\n\t\t\t\t\tprofit\n\t\t\t\t\treferrals\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery referralInfo {\n\t\t\treferralInfo {\n\t\t\t\tlevel\n\t\t\t\tfirstDepositBonus\n\t\t\t\tfutureDepositBonus\n\t\t\t\tcurrentLevelReferrals\n\t\t\t\tnextLevelReferrals\n\t\t\t\tcurrentLevelDeposits\n\t\t\t\tnextLevelDeposits\n\t\t\t\tbalance\n\t\t\t\tcodes {\n\t\t\t\t\tcode\n\t\t\t\t\tprofit\n\t\t\t\t\treferrals\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery charts($codes: [String!]) {\n\t\t\treferralCodesCharts(codes: $codes) {\n\t\t\t\tcharts {\n\t\t\t\t\tname\n\t\t\t\t\tdeposits {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\tprofit {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t\ttotal\n\t\t\t\t\t}\n\t\t\t\t\treferrals {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery charts($codes: [String!]) {\n\t\t\treferralCodesCharts(codes: $codes) {\n\t\t\t\tcharts {\n\t\t\t\t\tname\n\t\t\t\t\tdeposits {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t\tprofit {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t\ttotal\n\t\t\t\t\t}\n\t\t\t\t\treferrals {\n\t\t\t\t\t\tpoints {\n\t\t\t\t\t\t\tdate\n\t\t\t\t\t\t\tvalue\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createReferralCode {\n\t\t\tcreateReferralCode\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createReferralCode {\n\t\t\tcreateReferralCode\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createGenerationByUUID(\n\t\t\t$category: GenerationCategories!\n\t\t\t$uuid: UUID\n\t\t\t$size: BoobsSizes!\n\t\t\t$pussy: PussyTypes!\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tclothoff: {\n\t\t\t\t\t\tcategory: $category\n\t\t\t\t\t\tgenerationUuid: $uuid\n\t\t\t\t\t\tboobsSize: $size\n\t\t\t\t\t\tpussyType: $pussy\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createGenerationByUUID(\n\t\t\t$category: GenerationCategories!\n\t\t\t$uuid: UUID\n\t\t\t$size: BoobsSizes!\n\t\t\t$pussy: PussyTypes!\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tclothoff: {\n\t\t\t\t\t\tcategory: $category\n\t\t\t\t\t\tgenerationUuid: $uuid\n\t\t\t\t\t\tboobsSize: $size\n\t\t\t\t\t\tpussyType: $pussy\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery getGeneration($uuid: UUID!) {\n\t\tgeneration: getGeneration(uuid: $uuid) {\n\t\t\ttype\n\t\t\tstatus\n\t\t\tresults {\n\t\t\t\terror\n\t\t\t\textension\n\t\t\t\tuploaded\n\t\t\t\tuuid\n\t\t\t\tliked\n\t\t\t}\n\t\t\toriginals {\n\t\t\t\tuuid\n\t\t\t\textension\n\t\t\t\tuploaded\n\t\t\t}\n\t\t\tcreated\n\t\t\tcategory\n\t\t\tboobsSize\n\t\t\tregion\n\t\t\tpussyType\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery getGeneration($uuid: UUID!) {\n\t\tgeneration: getGeneration(uuid: $uuid) {\n\t\t\ttype\n\t\t\tstatus\n\t\t\tresults {\n\t\t\t\terror\n\t\t\t\textension\n\t\t\t\tuploaded\n\t\t\t\tuuid\n\t\t\t\tliked\n\t\t\t}\n\t\t\toriginals {\n\t\t\t\tuuid\n\t\t\t\textension\n\t\t\t\tuploaded\n\t\t\t}\n\t\t\tcreated\n\t\t\tcategory\n\t\t\tboobsSize\n\t\t\tregion\n\t\t\tpussyType\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation likeGenerationTask($uuid: UUID!, $like: Boolean!) {\n\t\t\tlikeGenerationTask(uuid: $uuid, like: $like)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation likeGenerationTask($uuid: UUID!, $like: Boolean!) {\n\t\t\tlikeGenerationTask(uuid: $uuid, like: $like)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createGenerationClothoff(\n\t\t\t$category: GenerationCategories!\n\t\t\t$size: BoobsSizes!\n\t\t\t$pussy: PussyTypes!\n\t\t\t$photos: [PhotoInput!]\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tclothoff: {\n\t\t\t\t\t\tcategory: $category\n\t\t\t\t\t\tphotos: $photos\n\t\t\t\t\t\tboobsSize: $size\n\t\t\t\t\t\tpussyType: $pussy\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createGenerationClothoff(\n\t\t\t$category: GenerationCategories!\n\t\t\t$size: BoobsSizes!\n\t\t\t$pussy: PussyTypes!\n\t\t\t$photos: [PhotoInput!]\n\t\t) {\n\t\t\tgeneration: createGeneration2(\n\t\t\t\tinput: {\n\t\t\t\t\tclothoff: {\n\t\t\t\t\t\tcategory: $category\n\t\t\t\t\t\tphotos: $photos\n\t\t\t\t\t\tboobsSize: $size\n\t\t\t\t\t\tpussyType: $pussy\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createTelegramAvatarGeneration(\n\t\t\t$id: Int!\n\t\t\t$initData: String!\n\t\t\t$botUsername: String!\n\t\t\t$arguments: JSON!\n\t\t\t$groupId: String!\n\t\t) {\n\t\t\tcreateTelegramAvatarGeneration(\n\t\t\t\tid: $id\n\t\t\t\tinitData: $initData\n\t\t\t\tbotUsername: $botUsername\n\t\t\t\targuments: $arguments\n\t\t\t\tgroupId: $groupId\n\t\t\t)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createTelegramAvatarGeneration(\n\t\t\t$id: Int!\n\t\t\t$initData: String!\n\t\t\t$botUsername: String!\n\t\t\t$arguments: JSON!\n\t\t\t$groupId: String!\n\t\t) {\n\t\t\tcreateTelegramAvatarGeneration(\n\t\t\t\tid: $id\n\t\t\t\tinitData: $initData\n\t\t\t\tbotUsername: $botUsername\n\t\t\t\targuments: $arguments\n\t\t\t\tgroupId: $groupId\n\t\t\t)\n\t\t}\n\t"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;