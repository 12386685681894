<template>
	<ModalClose />
	<ModalTitleTabs
		:activeIndex="1"
		:tabs="[t('modals.SignIn.title'), t('modals.SignUp.title')]"
		@click="titleTab"
	/>
	<div class="w-full sm:w-[360px]">
		<div class="space-y-3.5">
			<BaseInput
				v-model="email"
				type="email"
				inputmode="email"
				placeholder="example@domain.com"
				@input="checkEmail"
				:error="emailError"
				@keydown.enter="signUp"
				autocomplete="email"
				flat
				ref="emailInput"
			>
				<template #label>
					{{ t('modals.PasswordReset.email.input') }}
				</template>
			</BaseInput>
			<BaseInput
				v-model="password"
				type="password"
				:placeholder="t('modals.PasswordReset.password.placeholder', [8])"
				@input="checkPassword"
				:error="passwordError"
				@keydown.enter="signUp"
				autocomplete="new-password"
				flat
			>
				<template #label>
					{{ t('modals.PasswordReset.password.input') }}
				</template>
			</BaseInput>
			<BaseInput
				v-model="repeatPassword"
				type="password"
				:placeholder="t('modals.PasswordReset.repeatPassword.placeholder')"
				:error="repeatPasswordError"
				@input="checkRepeatPassword"
				@keydown.enter="signUp"
				autocomplete="new-password"
				flat
			>
				<template #label>
					{{ t('modals.PasswordReset.repeatPassword.input') }}
				</template>
			</BaseInput>
		</div>
		<div
			class="mt-5 text-sm font-normal text-label-secondary *-[a]:font-medium *-[a]:text-pink *-[a]:no-underline"
			v-html="t('modals.SignUp.warning')"
		/>
		<div class="mt-5 space-y-2.5">
			<BaseCheckbox v-model="tosAndPrivacy">
				<template #label>
					{{ t('modals.SignUp.iAccept') }}
					<a href="https://nubeeai.com/tos.pdf" target="_blank">
						{{ t('modals.SignUp.tos') }}
					</a>
					{{ t('modals.SignUp.and') }}
					<a href="https://nubeeai.com/tos.pdf" target="_blank">
						{{ t('modals.SignUp.privacy') }}
					</a>
				</template>
			</BaseCheckbox>
			<BaseCheckbox v-model="thirdParty">
				<template #label>
					{{ t('modals.SignUp.thirdParty') }}
				</template>
			</BaseCheckbox>
		</div>
		<BaseButton
			size="md"
			color="pink"
			class="mt-5 w-full"
			@click="signUp"
			:disabled="btnDisabled"
		>
			{{ t('modals.SignUp.btn') }}
		</BaseButton>
	</div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import BaseButton from '../BaseButton.vue'
import BaseInput from '../BaseInput.vue'
import { useMutation } from '@vue/apollo-composable'
import { EmailAddressResolver } from 'graphql-scalars'
import { useModalStore } from '../../stores/modal'
import { useAlertStore } from '../../stores/alert'
import ModalTitleTabs from './ModalTitleTabs.vue'
import ModalClose from './ModalClose.vue'
import { useUserStore } from '../../stores/user'
import { graphql } from '../../gql'
import { useI18n } from 'vue-i18n'
import { hasErrorCode } from '../../composables/useErrorCatcher'
import BaseCheckbox from '../BaseCheckbox.vue'

const { t } = useI18n()

const titleTab = (index: number) => {
	if (index === 0) {
		open('SignIn')
	}
}

const emailInput = ref<InstanceType<any>>()

onMounted(() => {
	if (!emailInput.value) {
		return
	}

	emailInput.value.focus()
})

const email = ref('')
const emailError = ref('')
const password = ref('')
const passwordError = ref('')
const repeatPassword = ref('')
const repeatPasswordError = ref('')
const tosAndPrivacy = ref(false)
const thirdParty = ref(false)

const btnDisabled = computed(
	() =>
		loading.value ||
		loadingSignIn.value ||
		!!emailError.value ||
		!!passwordError.value ||
		!!repeatPasswordError.value ||
		!tosAndPrivacy.value ||
		!thirdParty.value
)

const checkEmail = () => {
	try {
		EmailAddressResolver.parseValue(email.value)

		if (email.value.includes('+')) {
			emailError.value = t('modals.SignUp.email.tagError')

			return false
		}

		emailError.value = ''

		return true
	} catch {
		emailError.value = t('modals.PasswordReset.email.error')

		return false
	}
}

const checkPassword = () => {
	if (repeatPassword.value) {
		checkRepeatPassword()
	}

	if (password.value.length < 8) {
		passwordError.value = t('modals.PasswordReset.password.min', [8])
		return false
	}
	if (password.value.length > 50) {
		passwordError.value = t('modals.PasswordReset.password.max', [50])
		return false
	}

	passwordError.value = ''

	return true
}

const checkRepeatPassword = () => {
	if (password.value !== repeatPassword.value) {
		repeatPasswordError.value = t('modals.PasswordReset.repeatPassword.error')
		return false
	}

	repeatPasswordError.value = ''

	return true
}

const { mutate, loading } = useMutation(
	graphql(`
		mutation signUp(
			$email: EmailAddress!
			$password: Password!
			$code: String
		) {
			signUp(email: $email, password: $password, referralCode: $code) {
				uuid
				totalCredits
				name
				emailVerified
				avatar
				provider
				acceptedTerms
				intercomHash
			}
		}
	`)
)

const { mutate: mutateSignIn, loading: loadingSignIn } = useMutation(
	graphql(`
		mutation signIn($email: EmailAddress!, $password: Password!) {
			signIn(email: $email, password: $password) {
				uuid
				totalCredits
				name
				emailVerified
				avatar
				provider
				acceptedTerms
				intercomHash
			}
		}
	`)
)

const { open, close } = useModalStore()
const alert = useAlertStore()
const { setCurrent, refetch, disableEmailRetry } = useUserStore()

const signUp = async () => {
	if (!checkEmail() || !checkPassword() || !checkRepeatPassword()) {
		return
	}
	if (!tosAndPrivacy.value) {
		return alert.push(t('modals.SignUp.acceptTosAndPrivacy'), 'red')
	}
	if (!thirdParty.value) {
		return alert.push(t('modals.SignUp.acceptThirdParty'), 'red')
	}

	const code = localStorage.getItem('referralCode') || undefined

	try {
		const result = await mutate({
			email: email.value,
			password: password.value,
			code
		})

		if (result?.data?.signUp) {
			setCurrent(result.data.signUp)
		}

		disableEmailRetry()
		open('VerifyEmail')
	} catch (err: any) {
		if (hasErrorCode(err, 'INVALID_CREDENTIALS')) {
			return alert.push(t('modals.SignIn.invalidEmailOrPassword'))
		}
		if (hasErrorCode(err, 'ALREADY_REGISTERED')) {
			return trySignIn()
		}
		if (hasErrorCode(err, 'ALREADY_LOGGED_IN')) {
			await refetch()
			close()
			return
		}

		alert.push(err.message)
	}
}

const trySignIn = async () => {
	try {
		const result = await mutateSignIn({
			email: email.value,
			password: password.value
		})

		if (result?.data?.signIn) {
			setCurrent(result?.data?.signIn)
		}

		close()
	} catch (err: any) {
		if (hasErrorCode(err, 'INVALID_CREDENTIALS')) {
			return (emailError.value = t('modals.SignUp.alreadyRegistered'))
		}
		if (hasErrorCode(err, 'ALREADY_LOGGED_IN')) {
			await refetch()
			close()
			return
		}
	}
}
</script>
